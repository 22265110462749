import CheckIcon from "../../images/check_circle.png";
import CheckIconWhite from "../../images/check_circlewhite.png";
export const planData = {
  title: "Simple, Transparent Plans. Let Start with Teenyfy.",
  subtitle: " Choose a plan that works for you.",
  items: [
    {
      id: 1,
      category: "Free",
      dark: false,
      textColor: false,
      heading: "Free Plan",
      cost: "Free",
      btntext: "Get Started",
      btnlink: "https://app.teenyfy.com/user/register",
      list: [
        {
          icon: CheckIcon,
          textColor: false,
          text: "API Access",
        },
        {
          icon: CheckIcon,
          textColor: false,
          text: "1,000 URLs / Month",
        },
        {
          icon: CheckIcon,
          textColor: false,
          text: " No Extra Urls",
        },
        {
          icon: CheckIcon,
          textColor: false,
          text: " Email Support",
        },
      ],
    },
    {
      id: 2,
      category: "Monthly",
      dark: true,
      textColor: true,
      heading: "Basic Plan",
      btn: "Popular",
      cost: "$0",
      validity: "/month",
      btntext: "Get Started",
      btnlink: "https://app.teenyfy.com/user/register",
      list: [
        {
          icon: CheckIconWhite,
          textColor: false,
          text: "API Access",
        },
        {
          icon: CheckIconWhite,
          textColor: false,
          text: " 1,000 URLs / Month",
        },
        {
          icon: CheckIconWhite,
          textColor: false,
          text: "$0.002 Per Extra URL",
        },
        {
          icon: CheckIconWhite,
          textColor: false,
          text: "Email Support",
        },
      ],
    },
    {
      id: 3,
      category: "Monthly",
      dark: false,
      textColor: false,
      heading: "Premium Plan",
      cost: "$10",
      validity: "/month",
      btntext: "Get Started",
      btnlink: "https://app.teenyfy.com/user/register",
      list: [
        {
          icon: CheckIcon,
          textColor: true,
          text: "API Access",
        },
        {
          icon: CheckIcon,
          textColor: true,
          text: "10,000 URLs / Month",
        },
        {
          icon: CheckIcon,
          textColor: true,
          text: " $0.002 Per Extra URL",
        },
        {
          icon: CheckIcon,
          textColor: true,
          text: "Email and Chat Support",
        },
      ],
    },
    {
      id: 4,
      category: "Yearly",
      dark: false,
      textColor: false,
      heading: "Advance Plan",
      cost: "$25",
      validity: "/month",
      btntext: "Get Started",
      btnlink: "https://app.teenyfy.com/user/register",
      list: [
        {
          icon: CheckIcon,
          textColor: false,
          text: "API Access",
        },
        {
          icon: CheckIcon,
          textColor: false,
          text: "30,000 URLs / Month",
        },
        {
          icon: CheckIcon,
          textColor: false,
          text: "$0.001 Per Extra URL",
        },
        {
          icon: CheckIcon,
          textColor: false,
          text: "Email,Chat and Phone Support",
        },
      ],
    },
  ],
  itemsDesktop: [
    {
      id: 1,
      category: "Free",
      dark: false,
      textColor: false,
      heading: "Free Plan",
      cost: "Free",
      btntext: "Get Started",
      btnlink: "https://app.teenyfy.com/user/register",
      list: [
        {
          icon: CheckIcon,
          textColor: false,
          text: "API Access",
        },
        {
          icon: CheckIcon,
          textColor: false,
          text: "1,000 URLs / Month",
        },
        {
          icon: CheckIcon,
          textColor: false,
          text: " No Extra Urls",
        },
        {
          icon: CheckIcon,
          textColor: false,
          text: " Email Support",
        },
      ],
    },
    {
      id: 2,
      category: "Monthly",
      dark: true,
      textColor: true,
      heading: "Basic Plan",
      btn: "Popular",
      cost: "$100",
      validity: "/year",
      btntext: "Get Started",
      btnlink: "https://app.teenyfy.com/user/register",
      list: [
        {
          icon: CheckIconWhite,
          textColor: false,
          text: "API Access",
        },
        {
          icon: CheckIconWhite,
          textColor: false,
          text: " 1,000 URLs / Month",
        },
        {
          icon: CheckIconWhite,
          textColor: false,
          text: "$0.002 Per Extra URL",
        },
        {
          icon: CheckIconWhite,
          textColor: false,
          text: "Email Support",
        },
      ],
    },
    {
      id: 3,
      dark: false,
      textColor: false,
      heading: "Premium Plan",
      cost: "$110",
      validity: "/year",
      btntext: "Get Started",
      btnlink: "https://app.teenyfy.com/user/register",
      list: [
        {
          icon: CheckIcon,
          textColor: true,
          text: "API Access",
        },
        {
          icon: CheckIcon,
          textColor: true,
          text: "10,000 URLs / Month",
        },
        {
          icon: CheckIcon,
          textColor: true,
          text: " $0.002 Per Extra URL",
        },
        {
          icon: CheckIcon,
          textColor: true,
          text: "Email and Chat Support",
        },
      ],
    },
    {
      id: 4,
      category: "Yearly",
      dark: false,
      textColor: false,
      heading: "Advance Plan",
      cost: "$250",
      validity: "/year",
      btntext: "Get Started",
      btnlink: "https://app.teenyfy.com/user/register",
      list: [
        {
          icon: CheckIcon,
          textColor: false,
          text: "API Access",
        },
        {
          icon: CheckIcon,
          textColor: false,
          text: "30,000 URLs / Month",
        },
        {
          icon: CheckIcon,
          textColor: false,
          text: "$0.001 Per Extra URL",
        },
        {
          icon: CheckIcon,
          textColor: false,
          text: "Email,Chat and Phone Support",
        },
      ],
    },
  ],
};

//----------------

export const monthlyData = [
  {
    id: 1,
    dark: false,
    textColor: false,
    heading: "Free Plan",
    cost: "Free",
    btntext: "Get Started",
    btnlink: "https://app.teenyfy.com/user/register",
    list: [
      {
        icon: CheckIcon,
        textColor: false,
        text: "250 URLs / Month",
      },
      {
        icon: CheckIcon,
        textColor: false,
        text: "$0.02 Per Extra URL",
      },
      {
        icon: CheckIcon,
        textColor: false,
        text: "1 Custom Domain",
      },
      {
        icon: CheckIcon,
        textColor: false,
        text: "10,000 Requests / Month",
      },
    ],
  },
  {
    id: 2,
    dark: false,
    textColor: false,
    heading: "Basic Plan",
    cost: "$15",
    validity: "/month",
    btntext: "Get Started",
    btnlink: "https://app.teenyfy.com/user/register",
    list: [
      {
        icon: CheckIcon,
        textColor: false,
        text: "750 URLs / Month",
      },
      {
        icon: CheckIcon,
        textColor: false,
        text: "$0.0175 Per Extra URL",
      },
      {
        icon: CheckIcon,
        textColor: false,
        text: "2 Custom Domain",
      },
      {
        icon: CheckIcon,
        textColor: false,
        text: "10,0000 Requests / Month",
      },
    ],
  },
  {
    id: 3,
    dark: true,
    textColor: true,
    heading: "StartUp Plan",
    btn: "Popular",
    cost: "$30",
    validity: "/month",
    btntext: "Get Started",
    btnlink: "https://app.teenyfy.com/user/register",
    list: [
      {
        icon: CheckIconWhite,
        textColor: true,
        text: "2,750 URLs / Month",
      },
      {
        icon: CheckIconWhite,
        textColor: true,
        text: "$0.01 Per Extra URL",
      },
      {
        icon: CheckIconWhite,
        textColor: true,
        text: "5 Custom Domain",
      },
      {
        icon: CheckIconWhite,
        textColor: true,
        text: "10,0000 Requests / Month",
      },
    ],
  },
  {
    id: 4,
    dark: false,
    textColor: false,
    heading: "Growth Plan",
    cost: "$75",
    validity: "/month",
    btntext: "Get Started",
    btnlink: "https://app.teenyfy.com/user/register",
    list: [
      {
        icon: CheckIcon,
        textColor: false,
        text: "9,750 URLs / Month",
      },
      {
        icon: CheckIcon,
        textColor: false,
        text: " $0.0075 Per Extra URL",
      },
      {
        icon: CheckIcon,
        textColor: false,
        text: "10 Custom Domain",
      },
      {
        icon: CheckIcon,
        textColor: false,
        text: "10,0000 Requests / Month",
      },
    ],
  },
];

export const YearlyData = [
  {
    id: 1,
    dark: false,
    textColor: false,
    heading: "Free Plan",
    cost: "Free",
    btntext: "Get Started",
    btnlink: "https://app.teenyfy.com/user/register",
    list: [
      {
        icon: CheckIcon,
        textColor: false,
        text: "250 URLs / Month",
      },
      {
        icon: CheckIcon,
        textColor: false,
        text: "$0.02 Per Extra URL",
      },
      {
        icon: CheckIcon,
        textColor: false,
        text: "1 Custom Domain",
      },
      {
        icon: CheckIcon,
        textColor: false,
        text: "10,000 Requests / Month",
      },
    ],
  },
  {
    id: 2,
    dark: false,
    textColor: false,
    heading: "Basic Plan",
    originalCost: "$15",
    cost: "$12",
    validity: "/month",
    text: "Yearly charges of $144",
    btntext: "Get Started",
    btnlink: "https://app.teenyfy.com/user/register",
    list: [
      {
        icon: CheckIcon,
        textColor: false,
        text: "750 URLs / Month",
      },
      {
        icon: CheckIcon,
        textColor: false,
        text: "$0.0175 Per Extra URL",
      },
      {
        icon: CheckIcon,
        textColor: false,
        text: "2 Custom Domain",
      },
      {
        icon: CheckIcon,
        textColor: false,
        text: "10,0000 Requests / Month",
      },
    ],
  },
  {
    id: 3,
    dark: true,
    textColor: true,
    heading: "StartUp Plan",
    btn: "Popular",
    originalCost: "$30",
    cost: "$24",
    validity: "/month",
    text: "Yearly charges of $288",
    btntext: "Get Started",
    btnlink: "https://app.teenyfy.com/user/register",
    list: [
      {
        icon: CheckIconWhite,
        textColor: true,
        text: "2,750 URLs / Month",
      },
      {
        icon: CheckIconWhite,
        textColor: true,
        text: "$0.01 Per Extra URL",
      },
      {
        icon: CheckIconWhite,
        textColor: true,
        text: "5 Custom Domain",
      },
      {
        icon: CheckIconWhite,
        textColor: true,
        text: "10,0000 Requests / Month",
      },
    ],
  },
  {
    id: 4,
    dark: false,
    textColor: false,
    heading: "Growth Plan",
    originalCost: "$75",
    cost: "$60",
    validity: "/month",
    text: "Yearly charges of $720",
    btntext: "Get Started",
    btnlink: "https://app.teenyfy.com/user/register",
    list: [
      {
        icon: CheckIcon,
        textColor: false,
        text: "9,750 URLs / Month",
      },
      {
        icon: CheckIcon,
        textColor: false,
        text: " $0.0075 Per Extra URL",
      },
      {
        icon: CheckIcon,
        textColor: false,
        text: "10 Custom Domain",
      },
      {
        icon: CheckIcon,
        textColor: false,
        text: "10,0000 Requests / Month",
      },
    ],
  },
];
