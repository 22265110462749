import CheckIcon from "../../../images/check_circle.png";
export const tableHeadMonthly = [
  { title: "Plans", id: 1, price: "" },
  { title: "Free", id: 2, price: "$0" },
  { title: "Basic", id: 3, price: "$15/mo" },
  { title: "StartUp", id: 4, price: "$30/mo" },
  { title: "Growth", id: 4, price: "$75/mo" },
];
export const tableHeadYearly = [
  { title: "Plans", id: 1, price: "" },
  { title: "Free", id: 2, price: "$0" },
  { title: "Basic", id: 3, price: "$12/mo" },
  { title: "StartUp", id: 4, price: "$24/mo" },
  { title: "Growth", id: 4, price: "$60/mo" },
];
export const tabledataMonthly = [
  {
    id: 1,
    plan: "URL",
    free: {
      icon: CheckIcon,
      text: "250",
    },
    basic: {
      icon: CheckIcon,
      text: "750",
    },
    startup: {
      icon: CheckIcon,
      text: "2,750",
    },
    growth: {
      icon: CheckIcon,
      text: "9,750",
    },
  },
  {
    id: 2,
    plan: "Requests",
    free: {
      icon: CheckIcon,
      text: "10000",
    },
    basic: {
      icon: CheckIcon,
      text: "100000",
    },
    startup: {
      icon: CheckIcon,
      text: "100000",
    },
    growth: {
      icon: CheckIcon,
      text: "1000000",
    },
  },
  {
    id: 3,
    plan: "Data Range",
    free: {
      icon: CheckIcon,
      text: "t - 1 mos",
    },
    basic: {
      icon: CheckIcon,
      text: "t - 2 mos",
    },
    startup: {
      icon: CheckIcon,
      text: "t - 6 mos",
    },
    growth: {
      icon: CheckIcon,
      text: "Unlimited",
    },
  },
  {
    id: 4,
    plan: "Log Retention",
    free: {
      icon: CheckIcon,
      text: "1 mos",
    },
    basic: {
      icon: CheckIcon,
      text: "3 mos",
    },
    startup: {
      icon: CheckIcon,
      text: "6 mos",
    },
    growth: {
      icon: CheckIcon,
      text: "Unlimited",
    },
  },
  {
    id: 5,
    plan: "Custom Domian",
    free: {
      icon: CheckIcon,
      text: "1",
    },
    basic: {
      icon: CheckIcon,
      text: "2",
    },
    startup: {
      icon: CheckIcon,
      text: "5",
    },
    growth: {
      icon: CheckIcon,
      text: "10",
    },
  },
  {
    id: 6,
    plan: "Api Support",
    free: {
      text: "__",
    },
    basic: {
      icon: CheckIcon,
      text: "Yes",
    },
    startup: {
      icon: CheckIcon,
      text: "Yes",
    },
    growth: {
      icon: CheckIcon,
      text: "Yes",
    },
  },
  {
    id: 7,
    plan: "Email Support",
    free: {
      icon: CheckIcon,
      text: "Yes",
    },
    basic: {
      icon: CheckIcon,
      text: "Yes",
    },
    startup: {
      icon: CheckIcon,
      text: "Yes",
    },
    growth: {
      icon: CheckIcon,
      text: "Yes",
    },
  },
  {
    id: 8,
    plan: "Chat Support",
    free: {
      text: "__",
    },
    basic: {
      icon: CheckIcon,
      text: "Yes",
    },
    startup: {
      icon: CheckIcon,
      text: "Yes",
    },
    growth: {
      icon: CheckIcon,
      text: "Yes",
    },
  },
  {
    id: 9,
    plan: "Custom Alerts & Notification ",
    free: {
      text: "__",
    },
    basic: {
      icon: CheckIcon,
      text: "Yes",
    },
    startup: {
      icon: CheckIcon,
      text: "Yes",
    },
    growth: {
      icon: CheckIcon,
      text: "Yes",
    },
  },
  {
    id: 10,
    plan: "Automatic Reports",
    free: {
      text: "__",
    },
    basic: {
      icon: CheckIcon,
      text: "Yes",
    },
    startup: {
      icon: CheckIcon,
      text: "Yes",
    },
    growth: {
      icon: CheckIcon,
      text: "Yes",
    },
  },
  {
    id: 11,
    plan: "Bulk Link Shortening",
    free: {
      text: "__",
    },
    basic: {
      icon: CheckIcon,
      text: "Yes",
    },
    startup: {
      icon: CheckIcon,
      text: "Yes",
    },
    growth: {
      icon: CheckIcon,
      text: "Yes",
    },
  },
  {
    id: 12,
    plan: "HTTPS Support",
    free: {
      // icon: CheckIcon,
      text: "__",
    },
    basic: {
      // icon: CheckIcon,
      text: "__",
    },
    startup: {
      // icon: CheckIcon,
      text: "__",
    },
    growth: {
      icon: CheckIcon,
      text: "Yes",
    },
  },
  {
    id: 13,
    plan: "Advance Anaytics",
    free: {
      text: "__",
    },
    basic: {
      text: "__",
    },
    startup: {
      icon: CheckIcon,
      text: "Yes",
    },
    growth: {
      icon: CheckIcon,
      text: "Yes",
    },
  },
  {
    id: 14,
    plan: "Team",
    free: {
      text: "__",
    },
    basic: {
      icon: CheckIcon,
      text: "1 Extra User",
    },
    startup: {
      icon: CheckIcon,
      text: "5 Extra User",
    },
    growth: {
      icon: CheckIcon,
      text: "5 Extra User",
    },
  },
  {
    id: 15,
    plan: "SLA Time",
    free: {
      icon: CheckIcon,
      text: "99.9% SLA uptime",
    },
    basic: {
      icon: CheckIcon,
      text: "99.9% SLA uptime",
    },
    startup: {
      icon: CheckIcon,
      text: "99.9% SLA uptime",
    },
    growth: {
      icon: CheckIcon,
      text: "99.9% SLA uptime",
    },
  },
];
export const tabledataYearly = [
  {
    id: 1,
    plan: "URL",
    free: {
      icon: CheckIcon,
      text: "250",
    },
    basic: {
      icon: CheckIcon,
      text: "750",
    },
    startup: {
      icon: CheckIcon,
      text: "2,750",
    },
    growth: {
      icon: CheckIcon,
      text: "9,750",
    },
  },
  {
    id: 2,
    plan: "Requests",
    free: {
      icon: CheckIcon,
      text: "10000",
    },
    basic: {
      icon: CheckIcon,
      text: "100000",
    },
    startup: {
      icon: CheckIcon,
      text: "100000",
    },
    growth: {
      icon: CheckIcon,
      text: "1000000",
    },
  },
  {
    id: 3,
    plan: "Data Range",
    free: {
      icon: CheckIcon,
      text: "t - 1 mos",
    },
    basic: {
      icon: CheckIcon,
      text: "t - 2 mos",
    },
    startup: {
      icon: CheckIcon,
      text: "t - 6 mos",
    },
    growth: {
      icon: CheckIcon,
      text: "Unlimited",
    },
  },
  {
    id: 4,
    plan: "Log Retention",
    free: {
      icon: CheckIcon,
      text: "1 mos",
    },
    basic: {
      icon: CheckIcon,
      text: "3 mos",
    },
    startup: {
      icon: CheckIcon,
      text: "6 mos",
    },
    growth: {
      icon: CheckIcon,
      text: "Unlimited",
    },
  },
  {
    id: 5,
    plan: "Custom Domian",
    free: {
      icon: CheckIcon,
      text: "1",
    },
    basic: {
      icon: CheckIcon,
      text: "2",
    },
    startup: {
      icon: CheckIcon,
      text: "5",
    },
    growth: {
      icon: CheckIcon,
      text: "10",
    },
  },
  {
    id: 6,
    plan: "Api Support",
    free: {
      text: "__",
    },
    basic: {
      icon: CheckIcon,
      text: "Yes",
    },
    startup: {
      icon: CheckIcon,
      text: "Yes",
    },
    growth: {
      icon: CheckIcon,
      text: "Yes",
    },
  },
  {
    id: 7,
    plan: "Email Support",
    free: {
      icon: CheckIcon,
      text: "Yes",
    },
    basic: {
      icon: CheckIcon,
      text: "Yes",
    },
    startup: {
      icon: CheckIcon,
      text: "Yes",
    },
    growth: {
      icon: CheckIcon,
      text: "Yes",
    },
  },
  {
    id: 8,
    plan: "Chat Support",
    free: {
      text: "__",
    },
    basic: {
      icon: CheckIcon,
      text: "Yes",
    },
    startup: {
      icon: CheckIcon,
      text: "Yes",
    },
    growth: {
      icon: CheckIcon,
      text: "Yes",
    },
  },
  {
    id: 9,
    plan: "Custom Alerts & Notification ",
    free: {
      text: "__",
    },
    basic: {
      icon: CheckIcon,
      text: "Yes",
    },
    startup: {
      icon: CheckIcon,
      text: "Yes",
    },
    growth: {
      icon: CheckIcon,
      text: "Yes",
    },
  },
  {
    id: 10,
    plan: "Automatic Reports",
    free: {
      text: "__",
    },
    basic: {
      icon: CheckIcon,
      text: "Yes",
    },
    startup: {
      icon: CheckIcon,
      text: "Yes",
    },
    growth: {
      icon: CheckIcon,
      text: "Yes",
    },
  },
  {
    id: 11,
    plan: "Bulk Link Shortening",
    free: {
      text: "__",
    },
    basic: {
      icon: CheckIcon,
      text: "Yes",
    },
    startup: {
      icon: CheckIcon,
      text: "Yes",
    },
    growth: {
      icon: CheckIcon,
      text: "Yes",
    },
  },
  {
    id: 12,
    plan: "HTTPS Support",
    free: {
      // icon: CheckIcon,
      text: "__",
    },
    basic: {
      // icon: CheckIcon,
      text: "__",
    },
    startup: {
      // icon: CheckIcon,
      text: "__",
    },
    growth: {
      icon: CheckIcon,
      text: "Yes",
    },
  },
  {
    id: 13,
    plan: "Advance Anaytics",
    free: {
      text: "__",
    },
    basic: {
      text: "__",
    },
    startup: {
      icon: CheckIcon,
      text: "Yes",
    },
    growth: {
      icon: CheckIcon,
      text: "Yes",
    },
  },
  {
    id: 14,
    plan: "Team",
    free: {
      text: "__",
    },
    basic: {
      icon: CheckIcon,
      text: "1 Extra User",
    },
    startup: {
      icon: CheckIcon,
      text: "5 Extra User",
    },
    growth: {
      icon: CheckIcon,
      text: "5 Extra User",
    },
  },
  {
    id: 15,
    plan: "SLA Time",
    free: {
      icon: CheckIcon,
      text: "99.9% SLA uptime",
    },
    basic: {
      icon: CheckIcon,
      text: "99.9% SLA uptime",
    },
    startup: {
      icon: CheckIcon,
      text: "99.9% SLA uptime",
    },
    growth: {
      icon: CheckIcon,
      text: "99.9% SLA uptime",
    },
  },
];
