import React from "react";
import {
  tabledataMonthly,
  tabledataYearly,
  tableHeadMonthly,
  tableHeadYearly,
} from "./data";
const Table = ({ plan, setPlan }) => {
  return (
    <div className="sm:max-w-[75rem] mx-auto mb-12">
      <div className="hidden sm:block px-2 xl:px-0">
        <table className="">
          {plan ? (
            <tr className=" font-roboto text-xl font-bold">
              {tableHeadMonthly.map((item) => (
                <th>
                  {item.title}
                  <br /> {item.price}
                </th>
              ))}
            </tr>
          ) : (
            <tr className=" font-roboto text-xl font-bold">
              {tableHeadYearly.map((item) => (
                <th>
                  {item.title}
                  <br /> {item.price}
                </th>
              ))}
            </tr>
          )}

          {plan ? (
            <>
              {tabledataMonthly.map((item, i) => {
                return (
                  <tr className="font-roboto" key={i}>
                    <td className="font-bold max-w-[100px]">{item.plan}</td>
                    <td className="text-sm">
                      <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 rounded-full flex-shrink-0">
                        {item.free.icon && (
                          <img
                            src={item.free.icon}
                            alt="chechmark"
                            className="w-3 h-3"
                          />
                        )}
                      </span>

                      {item.free.text}
                    </td>
                    <td className="text-sm">
                      <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 rounded-full flex-shrink-0">
                        {item.basic.icon && (
                          <img
                            src={item.basic.icon}
                            alt="chechmark"
                            className="w-3 h-3"
                          />
                        )}
                      </span>

                      {item.basic.text}
                    </td>
                    <td className="text-sm">
                      <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 rounded-full flex-shrink-0">
                        {item.startup.icon && (
                          <img
                            src={item.startup.icon}
                            alt="chechmark"
                            className="w-3 h-3"
                          />
                        )}
                      </span>
                      {item.startup.text}
                    </td>
                    <td className="text-sm">
                      <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 rounded-full flex-shrink-0">
                        {item.growth.icon && (
                          <img
                            src={item.growth.icon}
                            alt="chechmark"
                            className="w-3 h-3"
                          />
                        )}
                      </span>
                      {item.growth.text}
                    </td>
                  </tr>
                );
              })}
            </>
          ) : (
            <>
              {tabledataYearly.map((item, i) => {
                return (
                  <tr className="font-roboto" key={i}>
                    <td className="font-bold max-w-[100px]">{item.plan}</td>
                    <td className="text-sm">
                      <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 rounded-full flex-shrink-0">
                        {item.free.icon && (
                          <img
                            src={item.free.icon}
                            alt="chechmark"
                            className="w-3 h-3"
                          />
                        )}
                      </span>

                      {item.free.text}
                    </td>
                    <td className="text-sm">
                      <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 rounded-full flex-shrink-0">
                        {item.basic.icon && (
                          <img
                            src={item.basic.icon}
                            alt="chechmark"
                            className="w-3 h-3"
                          />
                        )}
                      </span>

                      {item.basic.text}
                    </td>
                    <td className="text-sm">
                      <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 rounded-full flex-shrink-0">
                        {item.startup.icon && (
                          <img
                            src={item.startup.icon}
                            alt="chechmark"
                            className="w-3 h-3"
                          />
                        )}
                      </span>
                      {item.startup.text}
                    </td>
                    <td className="text-sm">
                      <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 rounded-full flex-shrink-0">
                        {item.growth.icon && (
                          <img
                            src={item.growth.icon}
                            alt="chechmark"
                            className="w-3 h-3"
                          />
                        )}
                      </span>
                      {item.growth.text}
                    </td>
                  </tr>
                );
              })}
            </>
          )}
        </table>
      </div>
      {/* ---------------mobile view----------------- */}
      <div className="px-2 sm:hidden">
        <table className="px-1">
          <tr className="sm:hidden font-roboto text-base sm:text-xl font-bold">
            {plan ? (
              <div className="grid grid-cols-4">
                {tableHeadMonthly
                  .slice(1, tableHeadMonthly.length)
                  .map((item) => (
                    <th>
                      {item.title}
                      <br /> {item.price}
                    </th>
                  ))}
              </div>
            ) : (
              <div className="grid grid-cols-4">
                {tableHeadYearly
                  .slice(1, tableHeadYearly.length)
                  .map((item) => (
                    <th>
                      {item.title}
                      <br /> {item.price}
                    </th>
                  ))}
              </div>
            )}
          </tr>

          {plan ? (
            <>
              {tabledataMonthly.map((item, i) => {
                return (
                  <tr className="font-roboto sm:hidden" key={i}>
                    <td className="font-bold max-w-[100px] hidden sm:block">
                      {item.plan}
                    </td>
                    <td className="text-xs sm:text-sm w-full">
                      <p className="font-bold h-4"> {item.plan}</p>
                      <div className="grid grid-cols-4">
                        <div className="flex flex-col justify-end font-bold  w-24 sm:hidden">
                          <div className="flex items-center space-x-2 justify-start mt-2">
                            <p className="w-4 h-4  inline-flex items-center justify-center bg-gray-400 rounded-full flex-shrink-0">
                              {item.free.icon && (
                                <img
                                  src={item.free.icon}
                                  alt="chechmark"
                                  className="w-3 h-3"
                                />
                              )}
                            </p>
                            <p>{item.free.text}</p>
                          </div>
                        </div>
                        <div className="flex items-center space-x-2 mt-5 justify-start pl-1">
                          <div className="w-4 h-4 inline-flex items-center justify-center bg-gray-400 rounded-full flex-shrink-0">
                            {item.basic.icon && (
                              <img
                                src={item.basic.icon}
                                alt="chechmark"
                                className="w-3 h-3"
                              />
                            )}
                          </div>
                          <p>{item.basic.text}</p>
                        </div>
                        <div className="flex items-center space-x-2 mt-5 justify-start">
                          <p className="w-4 h-4  inline-flex items-center justify-center bg-gray-400 rounded-full flex-shrink-0">
                            {item.startup.icon && (
                              <img
                                src={item.startup.icon}
                                alt="chechmark"
                                className="w-3 h-3"
                              />
                            )}
                          </p>
                          <p>{item.startup.text}</p>
                        </div>
                        <div className="flex items-center space-x-2 mt-5 justify-start">
                          <p className="w-4 h-4  inline-flex items-center justify-center bg-gray-400 rounded-full flex-shrink-0">
                            {item.growth.icon && (
                              <img
                                src={item.growth.icon}
                                alt="chechmark"
                                className="w-3 h-3"
                              />
                            )}
                          </p>
                          <p>{item.growth.text}</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </>
          ) : (
            <>
              {tabledataYearly.map((item, i) => {
                return (
                  <tr className="font-roboto sm:hidden" key={i}>
                    <td className="font-bold max-w-[100px] hidden sm:block">
                      {item.plan}
                    </td>
                    <td className="text-xs sm:text-sm w-full">
                      <p className="font-bold h-4"> {item.plan}</p>
                      <div className="grid grid-cols-4">
                        <div className="flex flex-col justify-end font-bold  w-24 sm:hidden">
                          <div className="flex items-center space-x-2 justify-start mt-2">
                            <p className="w-4 h-4  inline-flex items-center justify-center bg-gray-400 rounded-full flex-shrink-0">
                              {item.free.icon && (
                                <img
                                  src={item.free.icon}
                                  alt="chechmark"
                                  className="w-3 h-3"
                                />
                              )}
                            </p>
                            <p>{item.free.text}</p>
                          </div>
                        </div>
                        <div className="flex items-center space-x-2 mt-5 justify-start pl-1">
                          <div className="w-4 h-4 inline-flex items-center justify-center bg-gray-400 rounded-full flex-shrink-0">
                            {item.basic.icon && (
                              <img
                                src={item.basic.icon}
                                alt="chechmark"
                                className="w-3 h-3"
                              />
                            )}
                          </div>
                          <p>{item.basic.text}</p>
                        </div>
                        <div className="flex items-center space-x-2 mt-5 justify-start">
                          <p className="w-4 h-4  inline-flex items-center justify-center bg-gray-400 rounded-full flex-shrink-0">
                            {item.startup.icon && (
                              <img
                                src={item.startup.icon}
                                alt="chechmark"
                                className="w-3 h-3"
                              />
                            )}
                          </p>
                          <p className="w-auto">{item.startup.text}</p>
                        </div>
                        <div className="flex items-center space-x-2 mt-5 justify-start">
                          <p className="w-4 h-4  inline-flex items-center justify-center bg-gray-400 rounded-full flex-shrink-0">
                            {item.growth.icon && (
                              <img
                                src={item.growth.icon}
                                alt="chechmark"
                                className="w-3 h-3"
                              />
                            )}
                          </p>
                          <p>{item.growth.text}</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </>
          )}
        </table>
      </div>
    </div>
  );
};

export default Table;
