import { Link } from "gatsby";
import React from "react";

const Enterprise = () => {
  return (
    <div className="max-w-[75rem] mx-auto pt-10 px-10 xl:px-0">
      <div className="bg-[#EAFAFA] sm:flex flex-col pt-10 pl-5 sm:pl-8 pb-14 rounded-[20px] mb-20">
        <h2 className="font-roboto text-4xl pb-9">Enterprise Plan</h2>
        <Link
          to="/contact-us/"
          className="bg-dark-green font-roboto text-white text-xl w-[240px] h-[60px] rounded-[20px] border-white font-opensans font-semibold cursor-pointer text-center py-5 flex justify-center items-center"
        >
          Contact sales
        </Link>
      </div>
    </div>
  );
};

export default Enterprise;
