import React from "react";
import { useState } from "react";
// import { useState } from "react";
import Enterprise from "../components/homepage/Enterprise-Plan/Enterprise";
import Pricing from "../components/homepage/Price";
import Table from "../components/homepage/Table/table";
import Layout from "../components/layout/index";
import Seo from "../components/seo";

const Index = () => {
  const [plan, setPlan] = useState(false);
  return (
    <Layout>
      <Seo
        slug="/pricing/"
        title="Pricing - Teenyfy"
        description="Get All feature Pricing details of Teenyfy and Enjoy all premium version of tool. Visit us!"
      />
      <Pricing plan={plan} setPlan={setPlan} />
      <Enterprise />
      <Table plan={plan} setPlan={setPlan} />
    </Layout>
  );
};

export default Index;
