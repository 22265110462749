import React from "react";
import GetStarted from "../getstarted/getstarted";
import { monthlyData, YearlyData } from "./config";

function Pricing({ plan, setPlan }) {
  return (
    <div>
      <main>
        <div className="container px-5 pt-8 py-0 md:py-24 mx-auto text-center">
          <h1 className="text-[45px] leading-[53px] xs:font-roboto text-[#1F1F39] max-w-xl m-auto">
            Simple, Transparent Plans. Let Start with Teenyfy.
          </h1>
          <h3 className="font-[400] text-[20px] leading-[28px] text-[#1F1F39] xs:font-roboto pt-4 mt-4">
            Choose a plan that works for you.
          </h3>
          <div className="flex justify-center items-center mt-10">
            <div className="relative w-44 rounded-full border-2 border-[#EAFAFA] flex items-center">
              <span className="px-1 top-3 text-sm absolute -left-14 z-[60] bg-[#FFEBA3] rounded-md font-bold">
                20% OFF
              </span>
              <div
                className={`text-xl rounded-full xs:font-roboto px-4 py-2 cursor-pointer relative z-50 toggle-1 move-left ${
                  !plan
                    ? "move-left text-white font-medium"
                    : "move-right text-[#636973]"
                }`}
                onClick={() => setPlan(false)}
                onKeyDown={() => setPlan(false)}
                role="presentation"
              >
                Yearly
              </div>
              <div
                className={` text-xl rounded-full xs:font-roboto px-2 py-2 cursor-pointer z-50 ${
                  plan ? "text-white font-medium" : "text-[#636973]"
                }`}
                onClick={() => setPlan(true)}
                onKeyDown={() => setPlan(true)}
                role="presentation"
              >
                Monthly
              </div>
            </div>
          </div>
          <section>
            <div className="container px-5 pt-24 mx-auto">
              {plan ? (
                <div className="flex flex-wrap -m-4">
                  {monthlyData.map((value) => {
                    return (
                      <div
                        className="w-auto mx-auto md:mx-0 xl:w-1/4 md:w-1/2 p-2 sm:p-4"
                        key={value.id}
                      >
                        <div
                          className={`min-h-[700px]  border-2 border-[#EAFAFA] py-6 px-6 xl:px-2 rounded-[20px]
                        ${value.dark ? "bg-dark-green" : " bg-[#EAFAFA]"}`}
                        >
                          <div className="mb-[49px] min-h-[59px]">
                            <h2
                              className={`font-bold text-[20px] leading-[28px] xs:font-roboto mt-10 mb-[5px] text-black ${
                                value.textColor ? "text-white" : "text-black"
                              }`}
                            >
                              {value.heading}
                            </h2>
                            {value.btn && (
                              <button className="bg-[#FFEBA3] w-[70px] h-[25px] pt-[3px] pb-[8px] px-[3px] text-black text-center rounded-[16px] text-[12px] font-roboto font-bold leading-[16px] no-underline border-2 border-[#FFEBA3]">
                                {value.btn}
                              </button>
                            )}
                          </div>

                          <h2
                            className={`font-bold text-[50px] leading-[35px] xs:font-roboto mb-20 ${
                              value.textColor ? "text-white" : "text-black"
                            }`}
                          >
                            {value.cost}
                            <subscript
                              className={`text-[15px] leading-[28px] xs:font-roboto ${
                                value.textColor ? "text-white" : "text-gray1"
                              }`}
                            >
                              {value.validity}
                            </subscript>
                          </h2>

                          <GetStarted
                            backgroundCol={`w-[240px] rounded-[20px]  hover:text-white xs:font-roboto font-medium text-black mx-auto mt-10 mb-20 ${
                              value.textColor
                                ? "bg-white hover:text-black"
                                : " bg-dark-green"
                            }`}
                            btntext={value.btntext}
                            btnlink={value.btnlink}
                          />
                          {value.list.map((item) => {
                            return (
                              <p
                                className={`flex items-center font-[400] text-[12px] md:text-[16px] leading-[24px] text-black  font-open-sans mb-2 ${
                                  value.textColor ? "text-white" : "text-black"
                                }`}
                              >
                                <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-black rounded-full flex-shrink-0">
                                  <img
                                    src={item.icon}
                                    alt="chechmark"
                                    className="w-3 h-3"
                                  />
                                </span>
                                {item.text}
                              </p>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="flex flex-wrap -m-4">
                  {YearlyData.map((value) => {
                    return (
                      <div
                        className="w-auto mx-auto md:mx-0 xl:w-1/4 md:w-1/2 p-2 sm:p-4"
                        key={value.id}
                      >
                        <div
                          className={`min-h-[700px]  border-2 border-[#EAFAFA] py-6 px-6 xl:px-2 rounded-[20px]
                        ${value.dark ? "bg-dark-green" : " bg-[#EAFAFA]"}`}
                        >
                          <div className="mb-[49px] min-h-[59px]">
                            <h3
                              className={`font-bold text-[20px] leading-[28px] xs:font-roboto mt-10 mb-[5px] text-black ${
                                value.textColor ? "text-white" : "text-black"
                              }`}
                            >
                              {value.heading}
                            </h3>

                            {value.btn && (
                              <button className="bg-[#FFEBA3] w-[70px] h-[25px] pt-[3px] pb-[8px] px-[3px] text-black text-center rounded-[16px] text-[12px] font-roboto font-bold leading-[16px] no-underline border-2 border-[#FFEBA3]">
                                {value.btn}
                              </button>
                            )}
                          </div>

                          <h2
                            className={`font-bold text-[50px] leading-[35px] xs:font-roboto my-4 ${
                              value.textColor ? "text-white" : "text-black"
                            }`}
                          >
                            <span
                              className={`text-[25px] line-through mr-2 ${
                                value.textColor ? "text-white" : "text-gray"
                              }`}
                            >
                              {value.originalCost}
                            </span>
                            {value.cost}
                            <subscript
                              className={`text-[15px] leading-[28px] xs:font-roboto ${
                                value.textColor ? "text-white" : "text-gray1"
                              }`}
                            >
                              {value.validity}
                            </subscript>
                          </h2>
                          <h3
                            className={`mb-20 text-sm ${
                              value.textColor ? "text-white" : "text-gray"
                            }`}
                          >
                            {value.text ? (
                              value.text
                            ) : (
                              <p className="invisible">hello</p>
                            )}
                          </h3>
                          <GetStarted
                            backgroundCol={`w-[240px] rounded-[20px]  hover:text-white xs:font-roboto font-medium text-black mx-auto mt-10 mb-20 ${
                              value.textColor
                                ? "bg-white hover:text-black"
                                : " bg-dark-green"
                            }`}
                            btntext={value.btntext}
                            btnlink={value.btnlink}
                          />
                          {value.list.map((item) => {
                            return (
                              <p
                                className={`flex items-center font-[400] text-[12px] md:text-[16px] leading-[24px] text-black  font-open-sans mb-2 ${
                                  value.textColor ? "text-white" : "text-black"
                                }`}
                              >
                                <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-black rounded-full flex-shrink-0">
                                  <img
                                    src={item.icon}
                                    alt="chechmark"
                                    className="w-3 h-3"
                                  />
                                </span>
                                {item.text}
                              </p>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </section>
        </div>
      </main>
    </div>
  );
}

export default Pricing;
